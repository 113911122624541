:root {
    --toastify-toast-min-height: 32px;
}

html {
    background: transparent;
}

body {
    margin: 0px auto;
    overflow: hidden;
    padding: 0;
    background: transparent;
    font-family: 'Merriweather', serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.account {
    height: 100vh;
    width: 100%;
    min-height: 100vh;
    display: flex;
    overflow-y: auto;
    background: #eee;
}

.account__wrapper {
	margin: 10px auto;
    padding: 10px calc((105vw - 1000px) / 2);
}

.account__wrapper2 {
	margin: 10px auto;
    padding: 10px calc((105vw - 1000px) / 2);
}

.account__card {
	background-color: #fff;
	padding: 50px 50px;
    width: 100%;
    max-width: 500px;
    overflow: auto;
}

.account__card2 {
	background-color: #fff;
	padding: 50px 50px;
    width: 100%;
    min-width: 100%;
    overflow: auto;
}

.account__profile {
	text-align: center;
}

.account__btn {
	width: 100%;
	margin-right: 0;
}

.account__name {
	font-size: 18px;
	text-transform: uppercase;
	font-weight: 700;
	line-height: 15px;
	margin: 5px 0 25px 0px;
}

.account__name2 {
	font-size: 18px;
	font-weight: 700;
	line-height: 20px;
	margin: 5px 0 25px 0px;
}

.account__sub1 {
	margin-top: 0;
	margin-bottom: 10px;
	color: #656262;
	font-size: 12px;
	line-height: 15px;
}

.account__sub2 {
	margin-top: 0;
	margin-bottom: 10px;
	color: #656262;
	font-size: 9px;
	line-height: 15px;
}

@media screen and (max-width: 520px) {
	.account__card {
		padding: 35px 30px;
	}
}

@media screen and (max-width: 425px) {
	.account__btns {
		flex-wrap: wrap;
	}
}

.form-control {
    margin: 10px 0px;
}

.container-fluid {
    padding: 0px;
}

.main-content {
    display: flex;
    flex-wrap: nowrap;
    padding: 15px 50px;
    background: transparent;
}

.Toastify__toast--default {
    font-size: 14px;
    margin: 5px;
    display: flex;
    background-color: #656262;
    white-space: pre-line !important;
}

.Toastify__toast-container--top-right {
    width: auto;
}

.display-size-normal {
    font-size: 14px;
}

.display-size-medium {
    font-size: 16px;
}

.display-size-large {
    font-size: 20px;
}

.player1-name {
    float: left;
    color:rgb(0, 0, 168)
}

.player2-name {
    float: left;
    color:rgb(165, 0, 8)
}

.player-name-green {
    color: #009a1a
}

.player-name-red {
    color: rgb(165, 0, 8)
}

.player-name-blue {
    color: rgb(0, 0, 168)
}

.kill-icon {
    float: left;
    padding-left: 10px;
    padding-right: 10px;
}
